import React from "react"
import SEO from "../../components/seo"
import Layout from "../../components/Layout"
import StoreItem from '../../components/StoreItem'
import { storeItems } from '../../data/store'
import './style.css'

const StorePage = () => (
  <Layout>
    <SEO title="STORE" />
    <div className="store-page">
        <div className="store-page__store-container">
            <div className="store-page__store-items">
                {
                    storeItems.map((item, index) => <StoreItem key={index} item={item} />)
                }
            </div>
        </div>
    </div>
  </Layout>
)

export default StorePage
