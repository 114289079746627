import swellCassette from '../images/HNY-WLSN-Swell-cassette.png'
import blacKostumeMask from '../images/BlacKostume-face-mask.png'
import blacKostumeSticker from '../images/BlacKostume-sticker.png'
import blacKostumeTShirt from '../images/BlacKostume-t-shirt.png'
import savrMixtapeCassette from '../images/savr-mixtape-cassette.png'
import savrStickers from '../images/savr-stickers.png'
import shadowCassette from '../images/shadow-cassette.png'

export const storeItems = [
    {
        name: 'Darling Coils - Shadow Cassette',
        image: shadowCassette,
        imageLink: 'https://darlingcoils.bandcamp.com/album/shadow',
        links: [
            {
                url: 'https://darlingcoils.bandcamp.com/album/shadow',
                text: '$13.00'
            },
        ],
    },
    {
        name: 'SAVR Stickers',
        image: savrStickers,
        imageLink: 'https://savrmusic.bandcamp.com/merch/savr-stickers',
        links: [
            {
                url: 'https://savrmusic.bandcamp.com/merch/savr-stickers',
                text: '$8.00'
            },
        ],
    },
    {
        name: 'mixtape volume 1',
        image: savrMixtapeCassette,
        imageLink: 'https://savrmusic.bandcamp.com/album/mixtape-1',
        links: [
            {
                url: 'https://savrmusic.bandcamp.com/album/mixtape-1',
                text: '$20.00'
            },
        ],
    },
    {
        name: 'BlacKostume - Sticker',
        image: blacKostumeSticker,
        imageLink: 'https://teespring.com/blackostume-classic?tsmac=store&tsmic=blackostume&pid=663&cid=102921',
        links: [
            {
                url: 'https://teespring.com/blackostume-classic?tsmac=store&tsmic=blackostume&pid=663&cid=102921',
                text: '$3.99'
            },
        ],
    },
    {
        name: 'BlacKostume - Classic T-Shirt',
        image: blacKostumeTShirt,
        imageLink: 'https://teespring.com/blackostume-classic?tsmac=store&tsmic=blackostume&pid=2&cid=2397',
        links: [
            {
                url: 'https://teespring.com/blackostume-classic?tsmac=store&tsmic=blackostume&pid=2&cid=2397',
                text: '$21.99'
            },
        ],
    },
    {
        name: 'BlacKostume - Classic Face Mask',
        image: blacKostumeMask,
        imageLink: 'https://teespring.com/blackostume-classic-mask?tsmac=store&tsmic=blackostume&pid=972&cid=103974',
        links: [
            {
                url: 'https://teespring.com/blackostume-classic-mask?tsmac=store&tsmic=blackostume&pid=972&cid=103974',
                text: '$14.99'
            },
        ],
    },
    {
        name: 'HNY WLSN - Swell Cassette',
        image: swellCassette,
        imageLink: 'https://hnywlsn.bandcamp.com/album/swell',
        links: [
            {
                url: 'https://hnywlsn.bandcamp.com/album/swell',
                text: '$9.00'
            },
        ],
    },
]
